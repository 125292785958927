import React, { useState } from "react";
import { Box, Paper, Typography, IconButton } from "@material-ui/core";
import Illustration from "../../../../assets/images/illustration-2.svg";
import TestimonialsIcon from "../../../../assets/images/testimonials/testimonialsIcon.svg";
import quoteIcon from "../../../../assets/images/testimonials/quoteIcon.svg";
import TestimonialImage from "../../../../assets/images/testimonials/testimonial-img.jpg";
import TestimonialImage2 from "../../../../assets/images/testimonials/testimonial-img-2.jpg";
import TestimonialImage3 from "../../../../assets/images/testimonials/testimonial-img-3.jpg";
import TestimonialImage4 from "../../../../assets/images/testimonials/testimonial-img-4.jpg";

import "./testimonials.scss";

const TestimonialData = [
  {
    id: 1,
    text:
      "Living in times where knowledge and opportunities are widely distributed across industries and geographies, this is a unique   platform for much needed cross-fertilization of ideas and mutual learning for companies and professionals.",
    name: "Olimpija Zaevska, Connect2MK Expert (Denmark/Macedonia)",
    title: "CEO",
    role:
      "Ph.D. Fellow International Business & Government | Industry 4.0 | Green finance | International development ",
    avatar: TestimonialImage,
  },
  {
    id: 2,
    text:
      "It is instrumental and essential to work with someone who is from Macedonian heritage, who knows and understands the economic position of Macedonia in the  world and who can help our company on the path for growth.",
    name: "Aleksandra Murgoska",
    title: "CEO & Founder",
    role: "CEO & Founder Limitless Solutions DOOEL Skopje",
    avatar: TestimonialImage3,
  },
  {
    id: 3,
    text:
      "Connect2MK is the real thing in the mentorship process for the ones who want to be sure that the advice will come from the right place and be provided by real professionals and entrepreneurs, experts from the diaspora, and as expatriates from   around the world, they know the market inside-out, which can help us grow and prosper in an international environment.",
    name: "Aleksandar Celeski",
    title: "Executive Director",
    role: "Executive Director DUEL Consulting",
    avatar: TestimonialImage2,
  },
  {
    id: 4,
    text:
      "As a Connect2MK expert, I was given the opportunity to connect with a Macedonian company looking to grow and improve its operations. Watching the company grow and succeed has been incredibly rewarding, and I am grateful for the opportunity to make a positive impact in the Macedonian business community. ",
    name: "Aleksandar Orovchanec, Connect2MK Expert (Canada)",
    title: "COO",
    role:
      "P.Eng., MBA 1st degree Learning & Development | Compliance & Product Safety",
    avatar: TestimonialImage4,
  },
];

const Testimonial = ({ testimonial }) => (
  <Paper className="testimonial-card">
    <img src={TestimonialsIcon} className="testimonial-icon" alt="" />
    <Typography variant="body2" className="testimonial-paragraph">
      {testimonial.text}
    </Typography>
    <Box display="flex" alignItems="center">
      <img
        className="testimonial-img"
        src={testimonial.avatar}
        alt={testimonial.name}
      />
      <Box>
        <Typography className="testimonial-name">{testimonial.name}</Typography>
        <Typography className="testimonial-role" variant="body2">
          {testimonial.role}
        </Typography>
      </Box>
    </Box>
  </Paper>
);

export const Testimonials = () => {
  const [selectedTestimonials, setSelectedTestimonials] = useState([
    TestimonialData[0],
    TestimonialData[1],
  ]);

  const [activeDotIndex, setActiveDotIndex] = useState(0);

  const handleDotClick = (selectedDotIndex) => {
    setSelectedTestimonials([
      TestimonialData[selectedDotIndex * 2],
      TestimonialData[selectedDotIndex * 2 + 1],
    ]);
    setActiveDotIndex(selectedDotIndex);
  };

  return (
    <Box className="testimonials-section">
      <Box className="quote-wrapper">
        <img className="quote-icon" src={quoteIcon} alt="" />
        <Typography className="quote-heading">
          “I am proud that through Connect2MK, Macedonia2025 unleashes the power
          of unity, using the brilliance of Macedonian minds to propel the
          excellence of Macedonian businesses into the global arena." <br />
          <br /> - John Bitove Jr.
        </Typography>
        <img
          className="quote-illustration"
          src={Illustration}
          alt="SVG Image"
        />
      </Box>
      <Box className="testimonials-wrapper">
        <Box className="testimonial-cards">
          <Box className="testimonial-box">
            <Testimonial testimonial={selectedTestimonials[0]} />
          </Box>
          <Box className="testimonial-box">
            <Testimonial testimonial={selectedTestimonials[1]} />
          </Box>
        </Box>
        <Box className="navigation-dots">
          {[0, 1].map((dotIndex) => (
            <IconButton
              key={dotIndex}
              onClick={() => handleDotClick(dotIndex)}
              className={dotIndex === activeDotIndex ? "active" : ""}
            >
              <div className="navigation-dot" />
            </IconButton>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
